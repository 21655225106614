import "./App.css";
import { Title } from "./title/title";
import { AdSpace } from "./adspace/adspace";
import { TableWithComputedColumn } from "./coetable/coetable";
import { CuratedDealsTable } from "./curateddealstable/curatedDealsTable";

function App() {
  return (
    <div className="App">
      <Title />
      <TableWithComputedColumn />
      <CuratedDealsTable />
      <AdSpace />
    </div>
  );
}

export default App;
