import React, { useState } from "react";
import "./AdSpace.css";

export function AdSpace() {
  return (
    <div className="adspace-outer-container">
      <div className="adspace-container">
        <a
          className="adspace-content"
          href="https://www.hyundai.com/sg/ioniq-6/highlights"
          target="_blank"
        >
          Hyundai Ionic 6 - World Car of the Year 2023
        </a>
      </div>
      <div className="adspace-container" style={{ marginTop: 20 + "px" }}>
        <a
          className="adspace-content"
          href="mailto:beatthecoe@gmail.com?subject=Advertising%20Opportunity!"
          target="_blank"
        >
          Car ad collabs, contact me!
        </a>
      </div>
    </div>
  );
}
