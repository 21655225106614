import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import "./curatedDealsTable.css";

export function CuratedDealsTable() {
  const [data, setData] = useState([
    {
      id: 1,
      name: "Chevrolet Cruze 1.6A",
      price: 12774,
      coeexpiry_years: 1,
      coeexpiry_months: 8,
      coeexpiry_days: 21,
      roadtaxprice: 742,
      roadtaxyears: 2,
      source: "https://www.sgcarmart.com/used_cars/info.php?ID=1349909",
    },
    {
      id: 2,
      name: "Ssangyong Tivoli 1.6A",
      price: 9806.5,
      coeexpiry_years: 1,
      coeexpiry_months: 2,
      coeexpiry_days: 13,
      roadtaxprice: 742,
      roadtaxyears: 1.5,
      source: "https://www.sgcarmart.com/used_cars/info.php?ID=1347082",
    },
    {
      id: 3,
      name: "Hyundai Verna 1.6A",
      price: 23800,
      coeexpiry_years: 2,
      coeexpiry_months: 10,
      coeexpiry_days: 19,
      roadtaxprice: 1116,
      roadtaxyears: 3,
      source: "https://www.sgcarmart.com/used_cars/info.php?ID=1318489",
    },
    {
      id: 4,
      name: "Nissan Murano 2.5A",
      price: 15000,
      coeexpiry_years: 1,
      coeexpiry_months: 9,
      coeexpiry_days: 24,
      roadtaxprice: 2679,
      roadtaxyears: 2,
      source: "https://www.sgcarmart.com/used_cars/info.php?ID=1341367",
    },
    {
      id: 5,
      name: "Nissan Qashqai 1.2A DIG-T",
      price: 21655.5,
      coeexpiry_years: 1,
      coeexpiry_months: 10,
      coeexpiry_days: 6,
      roadtaxprice: 508,
      roadtaxyears: 2,
      source: "https://www.sgcarmart.com/used_cars/info.php?ID=1321440",
    },
  ]);

  const computeNettPricePerMonth = (
    price,
    coeexpiry_years,
    coeexpiry_months,
    coeexpiry_days,
    roadtaxprice,
    roadtaxyears
  ) => {
    let carmonthsleft =
      coeexpiry_years * 12 + coeexpiry_months + coeexpiry_days / 30;
    let carpricepermonth = price / carmonthsleft;
    let roadtaxpermonth = (roadtaxprice * roadtaxyears) / carmonthsleft;
    return carpricepermonth + roadtaxpermonth;
  };

  const handleNameChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, name: event.target.value };
      }
      return item;
    });
    setData(newData);
  };

  const handlePriceChange = (id, new_price) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          price: parseFloat(new_price) || 0,
        };
      }
      return item;
    });
    setData(newData);
  };

  const handleCoeExpiryYearsChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          coeexpiry_years: parseFloat(event.target.value) || 0,
        };
      }
      return item;
    });
    setData(newData);
  };

  const handleCoeExpiryMonthsChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          coeexpiry_months: parseFloat(event.target.value) || 0,
        };
      }
      return item;
    });
    setData(newData);
  };

  const handleCoeExpiryDaysChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, coeexpiry_days: parseFloat(event.target.value) || 0 };
      }
      return item;
    });
    setData(newData);
  };

  const handleRoadTaxPriceChange = (id, new_price) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          roadtaxprice: parseFloat(new_price) || 0,
        };
      }
      return item;
    });
    setData(newData);
  };

  const handleRoadTaxYearsChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, roadtaxyears: parseFloat(event.target.value) || 0 };
      }
      return item;
    });
    setData(newData);
  };

  return (
    <div className="page-container">
      <div className="title">
        <h2>🚘 Curated Deals 🚘</h2>
        <h3>as of 7 Nov 2024</h3>
      </div>
      <div className="table-container">
        <table className="custom-table">
          <tbody>
            <tr>
              <th>Car Model</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) => handleNameChange(item.id, e)}
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>Price</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <CurrencyInput
                    id={item.id}
                    placeholder="Please enter a number"
                    prefix="$"
                    defaultValue={item.price}
                    onValueChange={(value, name, values) =>
                      handlePriceChange(item.id, values.float)
                    }
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>COE Left</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <div class="coe-expiry-cell">
                    <input
                      type="number"
                      defaultValue="0"
                      value={Number(item.coeexpiry_years).toString()}
                      onChange={(e) => handleCoeExpiryYearsChange(item.id, e)}
                    />
                    Y
                    <input
                      type="number"
                      step="any"
                      value={Number(item.coeexpiry_months).toString()}
                      onChange={(e) => handleCoeExpiryMonthsChange(item.id, e)}
                    />
                    M
                    <input
                      type="number"
                      step="any"
                      value={Number(item.coeexpiry_days).toString()}
                      onChange={(e) => handleCoeExpiryDaysChange(item.id, e)}
                    />
                    D
                  </div>
                </td>
              ))}
            </tr>

            <tr>
              <th>Road Tax / Year</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <CurrencyInput
                    id={item.id}
                    placeholder="Please enter a number"
                    prefix="$"
                    defaultValue={item.roadtaxprice}
                    value={Number(item.roadtaxprice).toString()}
                    onValueChange={(value, name, values) =>
                      handleRoadTaxPriceChange(item.id, values.float)
                    }
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>
                Road Tax
                <br />
                (Remaining Years)
              </th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <input
                    type="number"
                    defaultValue="0"
                    value={Number(item.roadtaxyears).toString()}
                    onChange={(e) => handleRoadTaxYearsChange(item.id, e)}
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>Nett Price / Year</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  {"$" +
                    (
                      computeNettPricePerMonth(
                        item.price,
                        item.coeexpiry_years,
                        item.coeexpiry_months,
                        item.coeexpiry_days,
                        item.roadtaxprice,
                        item.roadtaxyears
                      ) * 12
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </td>
              ))}
            </tr>

            <tr>
              <th>Nett Price / Month</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  {"$" +
                    computeNettPricePerMonth(
                      item.price,
                      item.coeexpiry_years,
                      item.coeexpiry_months,
                      item.coeexpiry_days,
                      item.roadtaxprice,
                      item.roadtaxyears
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </td>
              ))}
            </tr>

            <tr>
              <th>Link</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <a
                    href={item.source}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="car-link"
                  >
                    source
                  </a>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="formula-container">
        <i>Formulae:</i>
        <br />
        <b>Car Cost / Month</b> = Price / (Years x 12 + Months + Days / 30)
        <br />
        <b>Road Tax / Month</b> = (Road Tax x Remaining Years) / (Year x 12 +
        Months + Days / 30)
        <br />
        <b>Nett Price / Month</b> = Car Cost per Month + Road Tax per Month
        <br />
        <b>PARF Rebate?</b> Minus the rebate from the car's Price before keying
        in.
      </div>
    </div>
  );
}
