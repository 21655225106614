import React, { useState } from "react";
import "./Title.css";

export function Title() {
  return (
    <div className="title-container">
      <h1 className="title">Beat the COE</h1>
      <h3 className="subtitle"> 🚗 🚗 🚗 ONLY BUY COE CARS</h3>
    </div>
  );
}
