import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import "./TableWithComputedColumn.css";

export function TableWithComputedColumn() {
  const [data, setData] = useState([
    {
      id: 1,
      name: "BMW 1 Series 116D",
      price: 21000,
      coeexpiry_years: 1,
      coeexpiry_months: 8,
      coeexpiry_days: 6,
      roadtaxprice: 1082,
      roadtaxyears: 2,
    },
    {
      id: 2,
      name: "Audi A6 2.0A TFSI MU",
      price: 21800,
      coeexpiry_years: 2,
      coeexpiry_months: 3,
      coeexpiry_days: 4,
      roadtaxprice: 1791,
      roadtaxyears: 2.5,
    },
  ]);

  const computeNettPricePerMonth = (
    price,
    coeexpiry_years,
    coeexpiry_months,
    coeexpiry_days,
    roadtaxprice,
    roadtaxyears
  ) => {
    let carmonthsleft =
      coeexpiry_years * 12 + coeexpiry_months + coeexpiry_days / 30;
    let carpricepermonth = price / carmonthsleft;
    let roadtaxpermonth = (roadtaxprice * roadtaxyears) / carmonthsleft;
    return carpricepermonth + roadtaxpermonth;
  };

  const handleNameChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, name: event.target.value };
      }
      return item;
    });
    setData(newData);
  };

  const handlePriceChange = (id, new_price) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          price: parseFloat(new_price) || 0,
        };
      }
      return item;
    });
    setData(newData);
  };

  const handleCoeExpiryYearsChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          coeexpiry_years: parseFloat(event.target.value) || 0,
        };
      }
      return item;
    });
    setData(newData);
  };

  const handleCoeExpiryMonthsChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          coeexpiry_months: parseFloat(event.target.value) || 0,
        };
      }
      return item;
    });
    setData(newData);
  };

  const handleCoeExpiryDaysChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, coeexpiry_days: parseFloat(event.target.value) || 0 };
      }
      return item;
    });
    setData(newData);
  };

  const handleRoadTaxPriceChange = (id, new_price) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          roadtaxprice: parseFloat(new_price) || 0,
        };
      }
      return item;
    });
    setData(newData);
  };

  const handleRoadTaxYearsChange = (id, event) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, roadtaxyears: parseFloat(event.target.value) || 0 };
      }
      return item;
    });
    setData(newData);
  };

  const addCar = () => {
    const newRow = {
      id: data.length + 1,
      name: `Item ${data.length + 1}`,
      price: 8888,
      coeexpiry_years: 1,
      coeexpiry_months: 1,
      coeexpiry_days: 1,
      roadtaxprice: 1103,
      roadtaxyears: 1.5,
    };
    setData([...data, newRow]);
  };

  const deleteCar = () => {
    if (data.length > 0) {
      setData(data.slice(0, -1));
    }
  };

  return (
    <div className="page-container">
      <div className="table-container">
        <table className="custom-table">
          <tbody>
            <tr>
              <th>Car Model</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <input
                    type="text"
                    value={item.name}
                    onChange={(e) => handleNameChange(item.id, e)}
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>Price</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <CurrencyInput
                    id={item.id}
                    placeholder="Please enter a number"
                    prefix="$"
                    defaultValue={item.price}
                    onValueChange={(value, name, values) =>
                      handlePriceChange(item.id, values.float)
                    }
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>COE Left</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <div class="coe-expiry-cell">
                    <input
                      type="number"
                      defaultValue="0"
                      value={Number(item.coeexpiry_years).toString()}
                      onChange={(e) => handleCoeExpiryYearsChange(item.id, e)}
                    />
                    Y
                    <input
                      type="number"
                      step="any"
                      value={Number(item.coeexpiry_months).toString()}
                      onChange={(e) => handleCoeExpiryMonthsChange(item.id, e)}
                    />
                    M
                    <input
                      type="number"
                      step="any"
                      value={Number(item.coeexpiry_days).toString()}
                      onChange={(e) => handleCoeExpiryDaysChange(item.id, e)}
                    />
                    D
                  </div>
                </td>
              ))}
            </tr>

            <tr>
              <th>Road Tax / Year</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <CurrencyInput
                    id={item.id}
                    placeholder="Please enter a number"
                    prefix="$"
                    defaultValue={item.roadtaxprice}
                    value={Number(item.roadtaxprice).toString()}
                    onValueChange={(value, name, values) =>
                      handleRoadTaxPriceChange(item.id, values.float)
                    }
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>
                Road Tax
                <br />
                (Remaining Years)
              </th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  <input
                    type="number"
                    defaultValue="0"
                    value={Number(item.roadtaxyears).toString()}
                    onChange={(e) => handleRoadTaxYearsChange(item.id, e)}
                  />
                </td>
              ))}
            </tr>

            <tr>
              <th>Nett Price / Year</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  {"$" +
                    (
                      computeNettPricePerMonth(
                        item.price,
                        item.coeexpiry_years,
                        item.coeexpiry_months,
                        item.coeexpiry_days,
                        item.roadtaxprice,
                        item.roadtaxyears
                      ) * 12
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </td>
              ))}
            </tr>

            <tr>
              <th>Nett Price / Month</th>
              {data.map((item) => (
                <td key={`name-${item.id}`}>
                  {"$" +
                    computeNettPricePerMonth(
                      item.price,
                      item.coeexpiry_years,
                      item.coeexpiry_months,
                      item.coeexpiry_days,
                      item.roadtaxprice,
                      item.roadtaxyears
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className="buttons-container">
          <button className="add-car-button" onClick={addCar}>
            Add Car
          </button>
          <button className="delete-car-button" onClick={deleteCar}>
            Delete Last Car
          </button>
        </div>
      </div>
    </div>
  );
}
